<template>
  <!-- 政策法规 -->
  <div id="laws">
    <div class="pageCont container tl">
      <bread class="nav" :title="title" :subtitle="subtitle"></bread>
      <el-divider></el-divider>
      <!-- v-if="showData" -->
      <div v-loading="loading" element-loading-text="拼命加载中">
        <!-- <el-tabs v-model="activeName" @tab-click="toChangeJYLB">
          <el-tab-pane
            v-for="(item,index) in navFirstLists"
            :key="index"
            :label="item.name"
            :name="item.code.toString()"
          >
            <div class="navs">
              <ul class="navUl flex">
                <li
                  v-for="(item,index) in navSecondLists"
                  :key="index"
                  :class="{'active': index==isActiveClick}"
                  @click="toChangeAddress(index)"
                >{{item.name}}</li>
              </ul>
            </div>
           
          </el-tab-pane>
        </el-tabs> -->
         <div class="notice-cont" v-if="showData" style="min-height:300px;">
              <ul class="contUl">
                <li
                  class="contLi"
                  v-for="(item,index) in lists"
                  :key="index"
                  @click="lookDetail(item.data_id)"
                >
                  <p class="news text-18 text-bold">{{item.title}}</p>
                  <p class="date text-14">
                    <img style="margin:-2px 5px 0 0;" src="~@/assets/images/time.png" alt>
                    {{item.createtime | filterTime}}
                  </p>
                </li>
              </ul>
            </div>
            <div v-else class="notice-cont margin-top" style="min-height:300px;">暂无数据！</div>
      </div>
      <div class="pagination">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="tableParams.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from "@/components/breadcrumb.vue";
export default {
  data() {
    return {
      loading: false,
      title: "政策法规",
      subtitle: "",
      activeName: "0", // 一级高亮
      isActiveClick: 0, // 二级高亮
      navFirstLists: [], // 一级菜单
      navSecondLists: [], // 二级菜单
      tableParams: {
        pageSize: 10,
        pageCurrent: 1,
        total: null
      },
      lists: [],
      showData: true,
      navList: [
        {
          name: "全部"
        },
        {
          name: "中央"
        },
        {
          name: "省级"
        },
        {
          name: "市级"
        }
      ]
    };
  },
  created() {
    // this.initZDB("zcfgjyfs"); // 政策法规种类(字典表：zcfgjyfs
    // this.initZDB("zcfgtype"); // 政策法规交易类别(字典表：zcfgtype
    this.getData(0, 0);
  },
  methods: {
    // 查询字典表--新闻动态标题
    initZDB(val) {
      let param = {
        dcode: val
      };
      this.apis.api.getSerchZDB(param).then(res => {
        if (res.data.code == "01") {
          // let datas = res.data.dat;
          // tab一级
          if (val == "zcfgjyfs") {
            this.navFirstLists = res.data.dat;
            this.navFirstLists.unshift({
              name: "全部",
              code: 0
            });
          } else if (val == "zcfgtype") {
            //  tab二级
            this.navSecondLists = res.data.dat;
            this.navSecondLists.unshift({
              name: "全部",
              code: 0
            });
          }
        }
      });
    },

    // 获取数据
    getData(val, val2) {
      let param = {
        zcfgjyfs: val,
        zcfgtype: val2,
        pageNum: this.tableParams.pageCurrent,
        pageSize: this.tableParams.pageSize
      };
      this.loading = true;
      this.apis.api.getLawsList(param).then(res => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          this.loading = false;
          this.lists = datas;
          // console.log(this.lists, "res**");

          this.tableParams.total = res.data.count;
          if (datas == "" || datas == null || datas == undefined) {
            this.showData = false;
          } else {
            this.showData = true;
          }
        }
      });
    },

    // 切换一级菜单
    toChangeJYLB(tab, event) {
      // console.log(tab.index, "一级索引**");
      this.isActiveClick = 0;
      this.tableParams.pageCurrent = 1;
      this.getData(tab.index, 0);
    },

    // 切换二级菜单
    toChangeAddress(index) {
      // console.log(index, "二级索引");

      this.tableParams.pageCurrent = 1;
      this.isActiveClick = index;
      this.getData(this.activeName, index);
    },

    // 跳转到详情页面
    lookDetail(item) {
      this.$router.push({
        name: "noticeDetail",
        query: {
          id: item,
          child: "政策法规",
          path: "/laws"
        }
      });
    },

    // 切换分页
    sizeChange(val) {
      var _this = this;
      _this.tableParams.pageSize = val;
      //方法  传参
      _this.getData(0, 0);
      //方法  传参
    },
    // 分页
    currentChange(val) {
      var _this = this;
      _this.tableParams.pageCurrent = val;
      _this.getData(0, 0);
    }
  },
  components: {
    bread
  }
};
</script>
<style lang="scss" scoped>
#laws {
  .pageCont {
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    .navs {
      width: 460px;
      height: 30px;
      .navUl {
        margin-top: 3px;
        li {
          margin-right: 10px;
          width: 80px;
          height: 30px;
          line-height: 30px;
          box-sizing: border-box;
          text-align: center;
          color: #333333;
          background-color: #fff;
          border-radius: 22px;
          border: 1px solid #ccc;
          cursor: pointer;
          &:hover {
            color: #fff;
            background-color: rgba(2, 167, 240, 1);
            border: none;
          }
          &:active {
            opacity: 0.8;
          }
        }
        .active {
          color: #fff;
          background-color: rgba(2, 167, 240, 1);
          border: none;
        }
      }
    }
    .notice-cont {
      // width: calc(100% - 180px);
      .contUl {
        .contLi {
          // display: flex;
          // justify-content: space-between;
          height: 85px;
          font-size: 16px;
          border-bottom: 1px solid #ccc;
          .news {
            padding: 20px 0 6px;
            box-sizing: border-box;
            width: 1000px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #000;
            cursor: pointer;
            &:hover {
              color: #02a7f0;
            }
          }
          .date {
            color: #aaaaaa;
            // &:hover {
            //   color: #0e83c9;
            // }
          }
        }
      }
    }
    .pagination {
      padding-top: 20px;
      height: 55px;
    }
  }

  // 分割线
  /deep/.el-divider--horizontal {
    margin: 0 0 10px;
  }

  /deep/.el-tabs__item {
    margin: 5px 0;
    font-size: 16px;
  }
}
</style>

